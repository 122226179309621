<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">Tickets Details</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="col comp-grid" >
                        <div class="">
                            <div >
                                <div class="relative-position" style="min-height:100px">
                                    <template v-if="pageReady">
                                        <div class="grid">
                                            <div class="col-12">
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Tickets: </div>
                                                        <div class="font-bold">{{ item.tickets }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Logstamp: </div>
                                                        <div class="font-bold">{{ item.logstamp }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Requestby: </div>
                                                        <div class="font-bold">{{ item.requestby }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Client: </div>
                                                        <div class="font-bold">{{ item.client }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Priority: </div>
                                                        <div class="font-bold">{{ item.priority }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Request: </div>
                                                        <div class="font-bold">{{ item.request }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Estimate: </div>
                                                        <div class="font-bold">{{ item.estimate }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Actual: </div>
                                                        <div class="font-bold">{{ item.actual }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Authorize: </div>
                                                        <div class="font-bold">{{ item.authorize }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Delivered: </div>
                                                        <div class="font-bold">{{ item.delivered }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Starttime: </div>
                                                        <div class="font-bold">
                                                            <Chip v-if="item.starttime" :label="$utils.relativeDate(item.starttime)" v-tooltip="{value: $utils.humanDatetime(item.starttime)}" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Stoptime: </div>
                                                        <div class="font-bold">
                                                            <Chip v-if="item.stoptime" :label="$utils.relativeDate(item.stoptime)" v-tooltip="{value: $utils.humanDatetime(item.stoptime)}" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Done: </div>
                                                        <div class="font-bold">{{ item.done }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Amount: </div>
                                                        <div class="font-bold">{{ item.amount }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Paidref: </div>
                                                        <div class="font-bold">{{ item.paidref }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="grid align-items-center">
                                                    <div class="col">
                                                        <div class="text-500 text-sm mb-1">Owner: </div>
                                                        <div class="font-bold">{{ item.owner }}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="flex  justify-btween">
                                                    <SplitButton class="p-button p-button-raised p-button-text p-button-sm" :model="getActionMenuModel(item)">
                                                        <i></i>
                                                    </SplitButton>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="loading">
                                        <div class="p-3 text-center">
                                            <ProgressSpinner style="width:50px;height:50px" />
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { utils } from '@/utils';
	import { useApp } from '@/composables/app.js';
	import { useViewPage } from '@/composables/viewpage.js';
	const props = defineProps({
		id: [String, Number],
		primaryKey: {
			type: String,
			default: 'tickets',
		},
		pageName: {
			type: String,
			default: 'tickets',
		},
		routeName: {
			type: String,
			default: 'ticketsview',
		},
		apiPath: {
			type: String,
			default: 'tickets/view',
		},
		editButton: {
			type: Boolean,
			default: true,
		},
		deleteButton: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		msgBeforeDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		}
	});
	const app = useApp(props);
	const page = useViewPage(props); // page hook
	//page state
	const { 
		item, // current record from store - Object
		loading, // api loading state
		pageReady, // api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const 
	{ 
		load, // load data from api
		deleteItem, // deleted current record
		exportPage, // export page records - args = (exportFormats, apiUrl, pageName)
		moveToNextRecord, // load next record from api
		moveToPreviousRecord // load previous record from api
	} = page.methods
	function getActionMenuModel(data){
		return [
		{
			label: "Edit",
			to: `/tickets/edit/${data.tickets}`,
			icon: "pi pi-pencil"
		},
		{
			label: "Delete",
			command: (event) => { deleteItem(data.tickets) },
			icon: "pi pi-minus-circle"
		}
	]
	}
	onMounted(()=>{ 
		const pageTitle = "Tickets Details";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
